import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals'
import { hashString } from 'utils/hash_string'
import uuid from 'superhuman-node-uuid'
import { IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING, SHOULD_TRACK } from 'shared/constants'
import { backendFetch } from 'shared/services/backend'
import { isSharedThreadJoinRequest } from 'team/components/shared_thread_helpers'
import { isMobile } from 'shared/utils'

/*
Lightweight event tracking framework. To use:
1. Optionally, call setEmail() to identify the user.
2. Call visitPage() to set the page context for further events.
3. Call action() to fire subsequent actions and display events.
*/

const COOKIE_NAME = 'web-device'

export default class Events {
  // generate a semi-persistent id so that we can find folks who come back repeatedly
  static setDevice() {
    if (typeof document === 'undefined') {
      return
    }
    const cookieStr = document.cookie.split('; ').find(row => row.startsWith(COOKIE_NAME))
    if (cookieStr) {
      Events.deviceID = cookieStr.split('=')[1]
    } else {
      Events.deviceID = uuid.v4()
      const expiresDate = new Date(Date.now() + 60 * 86400e3).toUTCString()
      let domain
      if (IS_DEVELOPMENT) {
        domain = 'localhost'
      } else if (IS_STAGING) {
        domain = 'staging-superhuman.com'
      } else if (IS_PRODUCTION) {
        domain = 'superhuman.com'
      }
      const cookieValue = `${Events.deviceID}; path=/; expires=${expiresDate}; domain=${domain}`
      document.cookie = COOKIE_NAME + '=' + cookieValue
    }
  }

  // setEmail sets the global email for all events
  static setEmail(_email) {
    Events.email = _email
  }

  // visitPage sets the global location to given page, and fires ui.visit for this page
  static visitPage(_page, fields = {}) {
    Events.page = _page
    fire('ui.visit', fields)
  }

  // action fires ui.action with given fields
  static action(actionName, fields) {
    return fire('ui.action', { action: actionName, ...fields })
  }

  static logWebVital({ name, value }) {
    return fire('measure.web_vital', { web_vital_name: name.toLowerCase(), web_vital_value: value })
  }

  static logGoogleAdsIdentifier() {
    // Fallback to param in case there is an issue with localStorage
    const searchParams =
      typeof window === 'undefined' ? { get: () => null } : new URLSearchParams(window.location.search)
    const gclid = localStorage.getItem('googleAdsIdentifier') || searchParams.get('gclid')
    if (gclid) {
      fire('log.google_ads_identifier', { gclid })
    }
  }

  static isSharedThreadJoinRequest() {
    return isSharedThreadJoinRequest()
  }

  // TODO:
  // 1. Move this function to pixelTracking.js.
  // 2. Rename it to fireFacebook for consistency.
  // 3. Rename pixelTracking.js to adTracking.js for accuracy.
  // 4. Remove this function call from other files.

  // trackFacebook sends facebook data through the backend
  // see clientToFacebook:
  // -- https://github.com/superhuman/backend/pull/3740/files#diff-1da2a61b3b932002594e7c78add35d44cb4183176c437ce897a99ee818540535R226
  // To test, run the backend, and open Facebook Business Manager on the development pixel:
  //   https://business.facebook.com/events_manager2/list/pixel/878704466327219/test_events?business_id=137960384724330&global_scope_id=137960384724330
  // Then call Events.trackFacebook('Lead', { testEventCode: X }), where X is provided by the facebook portal.
  // n.b.:
  // ____@superhuman.com events will be filtered out from test events on the fb dashboard.
  // to test, use another email domain :D
  static async trackFacebook(eventName, { testEventCode, emails } = {}) {
    if (typeof document === 'undefined') {
      return
    }
    const cookies = document.cookie.split('; ')
    let fbp
    let fbc
    cookies.forEach(cookie => {
      const [key, value] = cookie.split('=')
      if (key === '_fbp') {
        fbp = value
      } else if (key === '_fbc') {
        fbc = value
      }
    })

    if (!Events.email) {
      // Events with no email are not accepted by the backend.
      // This default is the same as is used by the iOS client
      Events.email = 'missing-email@example.com'
    }

    const eventID = uuid.v4()

    const em = emails ? emails.filter(e => !!e).map(e => hashString(e.toLowerCase())) : []

    const metric = 'log.facebook_conversion'
    // with the exception of test_event_code and event_set_id which are handled by the backend,
    // the body is passed through to Facebook as described here:
    // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event
    const data = {
      event_set_id: process.env.FACEBOOK_PIXEL_ID,
      event_source_url: window.location.href,
      event_name: eventName,
      event_id: eventID,
      action_source: 'website',
      // user_data is documented here, and passed through by the backend unchanged
      //  https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters/#em
      // client_user_agent and client_ip_address are set by the backend.
      user_data: { fbp, fbc, em },
      // custom_data is documented here, and passed through by the backend unchanged
      //   https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/custom-data
      custom_data: {},
      test_event_code: testEventCode
    }

    if (IS_DEVELOPMENT || IS_STAGING) {
      console.log('Firing event', metric, 'for', Events.email, 'with', data)
    }

    if (SHOULD_TRACK) {
      writeMetric(Events.email, metric, data, '')
    }
  }
}

// static class fields
Events.page = '<no page>'
Events.deviceID = '<no device>'
Events.email = getEmailFromSearchParams()
Events.timestamp = new Date()
Events.setDevice()

if (typeof document !== 'undefined') {
  getCLS(Events.logWebVital)
  getFCP(Events.logWebVital)
  getFID(Events.logWebVital)
  getLCP(Events.logWebVital)
  getTTFB(Events.logWebVital)
}

function getEmailFromSearchParams() {
  if (typeof window === 'undefined') return ''
  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get('email') || ''
  return email
}

export function fire(metric, fields = {}) {
  if (!Events.email) {
    // Events with no email are not accepted by the backend.
    // This default is the same as is used be the iOS client
    Events.email = 'missing-email@example.com'
  }

  const searchParams = typeof window === 'undefined' ? { get: () => null } : new URLSearchParams(window.location.search)
  for (const param of ['source', 'via', 'utm_source', 'utm_medium', 'utm_campaign', 'dub_id']) {
    const p = searchParams.get(param)
    if (p) {
      fields[param] = p
    }
  }

  const nextTS = new Date()
  fields.value = nextTS.getTime() - Events.timestamp.getTime()
  Events.timestamp = nextTS

  let referrer = ''
  if (typeof document !== 'undefined' && document.referrer) {
    referrer = filterParamsFromURL(document.referrer)
  }

  Object.assign(fields, {
    path: window.location.pathname, // if we ever store sensitive info in paths, we need to obfuscate here
    page: Events.page,
    user_agent: navigator && navigator.userAgent,
    referrer: referrer,
    url: filterParamsFromURL(window.location.href),
    device_type: isMobile() ? 'mobile' : 'desktop'
  })

  if (IS_DEVELOPMENT || IS_STAGING) {
    console.log('Firing event', metric, 'for', Events.email, 'with', fields) // comment out
  }

  if (SHOULD_TRACK) {
    writeMetric(Events.email, metric, fields, Events.deviceID)
  }
}

export async function writeMetric(email, name, fields = {}, deviceID) {
  if (!email) return

  try {
    await backendFetch('/v3/metrics.write', {
      headers: {
        'x-superhuman-user-email': email,
        'x-superhuman-device-id': deviceID
      },
      method: 'POST',
      body: JSON.stringify({
        dataset: 'website',
        metrics: [{ name, fields }]
      }),
      keepalive: true
    })
  } catch (e) {
    console.error(e)
    Bugsnag.notify(e, event => {
      event.addMetadata('details', { fetchUrl: '/v3/metrics.write', fields, metric: name })
    })
  }
}

export function filterParamsFromURL(url) {
  // add params that we don't want to log in Analytics to
  // paramsToFilter array
  const paramsToFilter = ['token', 'auth', 'secret']

  try {
    for (const param of paramsToFilter) {
      // Note:
      // 1. Use `replace` instead of `replaceAll` to support more browsers
      // 2. Don't use look behind assertion since that is not supported by
      // all browsers (especially Safari)
      url = url.replace(new RegExp('&\\w*' + param + '\\w*=[^?#&]*', 'gi'), '&')
      url = url.replace(new RegExp('\\?\\w*' + param + '\\w*=[^?#&]*', 'gi'), '?')
      url = url.replace(new RegExp('#\\w*' + param + '\\w*=[^?#&]*', 'gi'), '#')
    }
    return url
  } catch (e) {
    console.error(e)
    Bugsnag.notify(e)
    // return blank URL if there was an error and it wasn't
    // correctly filtered
    return ''
  }
}

if (typeof window !== 'undefined') {
  window.Events = Events
}
