import Bugsnag from '@bugsnag/js'
import { fire } from './events'

Bugsnag.start({
  apiKey: '36f15d5bb6217235d3d87259f96b92c0',
  enabledReleaseStages: ['staging', 'production'],
  releaseStage: process.env.APP_ENV,
  onError: event => {
    const report = event?.errors?.[0] || {}
    fire('log.bugsnag', {
      type: report.errorClass,
      severity: event.severity,
      message: report.errorMessage,
      metadata: JSON.stringify(report.metaData?.Other || {}),
      stacktrace: report.stacktrace
    })
  }
})

if (typeof window !== 'undefined') {
  window.Bugsnag = Bugsnag
}
