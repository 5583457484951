import 'utils/bugsnag';
import 'stylesheets/superhuman.scss';
/* Javascript included on the page begins here */
/* jQuery */
window.jQuery = window.$ = require('jquery');
/* Superhuman JS */
require('utils/ie10_viewport_bug_workaround.js');
require('utils/nav_bar.js');
const addSignUpFormEventHandlers = require('utils/signup.js');
$(document).ready(addSignUpFormEventHandlers.default);
require('assets/js/wow.min.js');
/*  eslint-disable */
new WOW().init();
// Store google ads identifier in case the user clicks around and loses the query param.
const params = new URLSearchParams(window.location.search);
const gclid = params.get('gclid');
if (gclid) {
    localStorage.setItem('googleAdsIdentifier', gclid);
}
