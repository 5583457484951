const SUPERHUMAN_DOMAINS = [
    '@sprhmn.onmicrosoft.com',
    '@sprh.mn',
    '@superhuman.com',
    '@superhuman.dev',
    '@superhumansecuritytest.com',
    '@superhumanoutlooktest.com',
    '@superhuman.fm',
    '@superhuman.email'
];
export const isIos = typeof window !== 'undefined' && (navigator === null || navigator === void 0 ? void 0 : navigator.userAgent.match(/ipad|iphone|ipod/i));
export function isIOS() {
    return navigator.platform.match(/ipad|iphone|ipod/i) || isIpad();
}
export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
export function isSafariOrIOS() {
    return isSafari() || isIOS();
}
export function isIpad() {
    return ((navigator.userAgent.match(/macintosh/i) && navigator.maxTouchPoints > 2) ||
        navigator.userAgent.match(/ipad/i) !== null);
}
export function isMobile() {
    return navigator.userAgent.match(/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i) !== null;
}
export function isMobileByWidth() {
    return window.innerWidth < 768;
}
export function mailTo({ to, subject = '', body = '' } = {}) {
    let uri = `mailto:${to}`;
    if (subject || body) {
        const params = new URLSearchParams({
            subject,
            body
        });
        uri = `${uri}?${params.toString()}`;
    }
    return uri;
}
export function gmailTo({ to = '', subject = '', body = '' }) {
    const params = new URLSearchParams({
        view: 'cm',
        fs: '1',
        su: subject,
        to,
        body
    });
    return `https://mail.google.com/mail/?${params.toString()}`;
}
export function isLocalStorageAvailable() {
    try {
        const value = 'value';
        const key = 'key';
        localStorage.setItem(key, value);
        const recoveredValue = localStorage.getItem(key);
        localStorage.removeItem(key);
        return recoveredValue === value;
    }
    catch (e) {
        return false;
    }
}
export function notifyBugsnag(e) {
    if (typeof window !== 'undefined' && window.Bugsnag) {
        if (e instanceof Error) {
            window.Bugsnag.notify(e);
        }
        else {
            window.Bugsnag.notify(new Error(JSON.stringify(e)));
        }
    }
}
export function isSuperhumanEmployee(email) {
    return SUPERHUMAN_DOMAINS.some(domain => (email || '').endsWith(domain));
}
export const isValidEmail = (email) => {
    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validEmailRegex.test(email);
};
