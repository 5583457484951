var KEY_APOSTROPHE = 39
var PRE_SCROLL_MARGIN_OPEN_POSITIONS = 32

function shouldToggleGrid(event) {
  return event.which === KEY_APOSTROPHE && !['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)
}

$(document).ready(function () {
  $('body').keypress(function (event) {
    if (shouldToggleGrid(event)) {
      $('.grid').toggle()
    }
  })

  $('#jobs .jobs-view-positions').click(function () {
    if ($('.open-positions')[0]) {
      var sectionTop = $('.open-positions').offset().top
      var scrollTop = sectionTop - PRE_SCROLL_MARGIN_OPEN_POSITIONS
      $('html, body').animate({ scrollTop: scrollTop })
    } else {
      window.location.href = '/jobs'
    }
  })

  // TODO: Consider replacing this with isMobile() or isMobileByWidth() from shared/utils.jsx.
  var isMobile = function () {
    return $(window).width() < 768
  }

  var updateBaselineStruts = function () {
    var struts = $('.baseline-strut')

    struts.each(function () {
      var offset = $(this).offset().top

      if (offset % 6) {
        $(this).height(6 - (offset % 6))
      } else {
        $(this).height(0)
      }
    })
  }

  var updateNavBarStyle = function () {
    var navBarContainer = $('#navbar-container')
    if (navBarContainer.length !== 0) {
      var c = []

      if (isMobile()) {
        c.push('mobile')
      }

      var navBar = navBarContainer.find('nav.navbar')
      var navBarPosition = navBar.offset().top + navBar.height()

      var currentSection = $("[class*='update-navbar']")
        .not('.hide')
        .filter(function (i, e) {
          return navBarPosition >= $(e).offset().top - 2
        })

      if (currentSection.length) {
        var currentSectionClass = currentSection
          .last()
          .attr('class')
          .split(' ')
          .filter(function (c) {
            return c !== 'container-fluid' && c.match(/^update-navbar/)
          })

        c.push.apply(c, currentSectionClass)

        window.c = c
      }

      navBarContainer.attr('class', c.join(' '))
    }
  }

  $(window).resize(function () {
    updateBaselineStruts()
    updateNavBarStyle()
  })

  $(window).scroll(function () {
    updateNavBarStyle()
    setTimeout(function () {
      updateNavBarStyle()
    }, 1000)
  })

  updateBaselineStruts()
  updateNavBarStyle()
})
