export function isSharedThreadJoinRequest() {
    const params = new URLSearchParams(window.location.search);
    return params.has('teamId');
}
export function getTeamId() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('teamId')) {
        return params.get('teamId');
    }
    else {
        // TODO: Figure out if we want to return something else
        return null;
    }
}
