import Events from 'utils/events'
import { isLocalStorageAvailable } from 'shared/utils'

export default function addSignUpFormEventHandlers() {
  document.querySelectorAll('a[href="/signup"]').forEach(link => {
    link.href += window.location.search
  })
}

function getVia() {
  const referrer = document.referrer
  if (referrer) {
    const referrerUrl = new URL(referrer)
    const referrerPath = referrerUrl.pathname
    const referrerPromoCode = referrerUrl.searchParams.get('promo_code') || ''

    // Because /signup is redirecting to /signup-new as part of our login migration,
    // document.referrer will be /signup but we want to track it as "refer/referral_code"
    const params = new URLSearchParams(window.location.search)
    const referralCode = params.get('referral_code')
    if (referralCode) {
      return encodeURI(`refer/${referralCode}`)
    }

    if (isSentViaSuperhumanLink()) {
      return 'refer'
    }

    // If user gets to /signup from any page other than the homepage, we use that pathname as the referrer.
    // If they're coming from the homepage, we try to use the promo_code param, or default to "signup".
    return referrerPath !== '/' ? referrerPath.slice(1) : referrerPromoCode || 'signup'
  }
  return 'unknown_referrer'
}

export function getSource() {
  const params = new URLSearchParams(window.location.search)
  const source = params.get('source')
  if (source) return source

  if (params.get('referral_code')) {
    return 'referral_link'
  }

  if (isSentViaSuperhumanLink()) return 'svs_link'

  const via = getVia()
  if (via !== 'signup') return 'partners_inf'
  return 'signup_site'
}

export function getDubId() {
  const params = new URLSearchParams(window.location.search)
  const cookieStr = document.cookie.split('; ').find(row => row.startsWith('dub_id'))

  if (cookieStr) {
    return cookieStr.split('=')[1]
  }
  return params.get('dub_id') || undefined
}

export function getSourceAndVia() {
  const via = getVia()
  const source = getSource()
  return { source, via }
}

export function isSentViaSuperhumanLink() {
  const searchParams = new URLSearchParams(window.location.search)
  const expectedMedium =
    searchParams.get('utm_medium') === 'signature' || searchParams.get('utm_medium') === 'calendar-event'

  return searchParams.get('utm_source') === 'product' && expectedMedium && !!searchParams.get('utm_campaign')
}

export function getResult(signup) {
  if (!signup) return 'error'
  if (signup.matchesTeam) return 'team_match'
  if (signup.matchesSingleton) return 'singleton_match'
  if (signup.isSecurityBlocked) return 'security_blocked'
  if (signup.shouldProceed) return 'proceed'
  if (signup.shouldMaybeProceed) return 'maybe_proceed'
  return 'do_not_proceed'
}

export function getNextPage(signup) {
  if (signup.matchesSingleton || signup.matchesTeam) {
    return 'team'
  }

  const searchParams = new URLSearchParams(window.location.search)

  const wasAlreadyOnPricing = searchParams.get('tier')
  if (wasAlreadyOnPricing) {
    return 'checkout'
  }

  const next = searchParams.get('next') || ''
  if (['checkout', 'onboarding'].includes(next)) {
    return next
  }

  return 'pricing'
}

export function getNextParams(email, signup, name = '') {
  const { source, via } = getSourceAndVia()
  const nextParams = new URLSearchParams({
    email,
    signup_email_address: email,
    signupSecret: signup.signupSecret,
    source,
    via
  })

  if (name) nextParams.set('name', name)

  if (signup.matchesSingleton) nextParams.set('singleton', '1')
  if (signup.matchesTeam) nextParams.set('team', '1')

  const currentParams = new URLSearchParams(window.location.search)

  if (isSentViaSuperhumanLink()) {
    const referrerEmail = currentParams.get('utm_campaign') || ''
    nextParams.set('referrer_email', referrerEmail)
  }

  const paramsToPreserve = [
    'name',
    'referral_email_address',
    'referrer_email_address',
    'gclid',
    // We pass these params from /pricing → /start → /checkout
    'billing',
    'promo_code',
    'tier',
    'users',
    'dub_id'
  ]
  for (const name of paramsToPreserve) {
    const value = currentParams.get(name)
    if (value) {
      nextParams.set(name, value)
    }
  }

  return nextParams.toString()
}

export async function proceedToNextPage(email, signupSecret, nextPage, nextUrl) {
  Events.action(`proceed_to_${nextPage}`)

  try {
    // Wait for proceedSignup to complete before redirecting
    await proceedSignup({
      email,
      signupSecret
    })

    // After the signup process is completed, navigate to the next page
    window.location.href = nextUrl
  } catch (error) {
    Bugsnag.notify(new Error('Error during signup process'), event => {
      event.addMetadata('details', { error })
    })
  }
}

export function getNavigatorDetails() {
  if (!navigator) {
    return {}
  }

  return {
    deviceMemory: navigator.deviceMemory,
    effectiveConnectionType: navigator.connection && navigator.connection.effectiveType,
    hardwareConcurrency: navigator.hardwareConcurrency,
    language: navigator.language,
    maxTouchPoints: navigator.maxTouchPoints
  }
}

export async function proceedSignup({ email, signupSecret }) {
  const url = process.env.SERVER_ORIGIN + '/~backend/v3/signups.proceed'
  const params = new URLSearchParams(window.location.search)
  const source = params.get('source') || ''
  const body = {
    emailAddress: email,
    pipelineStage: 'Pending Scheduling',
    signupSecret: signupSecret,
    source
  }
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 5000)
  const options = {
    method: 'POST',
    keepalive: true,
    signal: controller.signal,
    body: JSON.stringify(body)
  }
  let result = 'ok'
  // Remove from local storage.
  // This is the last step in signup process before moving to the next page.
  if (isLocalStorageAvailable()) {
    localStorage.removeItem('via')
  }
  try {
    const response = await fetch(url, options)
    clearTimeout(timeoutId)
    if (!response.ok) {
      result = 'error'
      const text = await response.text()
      Bugsnag.notify(new Error('Failed to proceed signup'), event => {
        event.addMetadata('details', {
          requestBody: body,
          responseText: text
        })
      })
    }
  } catch (error) {
    result = 'error'
    Bugsnag.notify(new Error('Failed to proceed signup'), event => {
      event.addMetadata('details', {
        requestBody: body,
        error
      })
    })
  }
  Events.action('proceed_signup', { result })
}

export const SIGNUP_CARD = {
  ERROR: 'error',
  MAYBE_PROCEED: 'maybe_proceed',
  SECURITY_BLOCKED: 'security_blocked',
  TURNED_DOWN: 'turned_down'
}

export function getCardToShow(signup) {
  if (signup.isSecurityBlocked) return SIGNUP_CARD.SECURITY_BLOCKED
  if (signup.shouldMaybeProceed) return SIGNUP_CARD.MAYBE_PROCEED
  return SIGNUP_CARD.TURNED_DOWN
}
