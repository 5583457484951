export const IS_DEVELOPMENT = process.env.APP_ENV === 'development';
export const IS_SEMAPHORE = process.env.APP_ENV === 'semaphore';
export const IS_STAGING = process.env.APP_ENV === 'staging';
export const IS_PRODUCTION = process.env.APP_ENV === 'production';
export const BACKEND_URL = `${process.env.SERVER_ORIGIN}/~backend`;
export const ORIGIN = process.env.ORIGIN;
export const NON_FRAMER_ORIGIN = process.env.NON_FRAMER_ORIGIN || process.env.ORIGIN;
export const SHOULD_TRACK = IS_STAGING || IS_PRODUCTION;
export const CHECKOUT_TIERS = {
    STARTER: 'starter',
    BUSINESS: 'business'
};
export const CHECKOUT_SKUS = {
    SKU30: 'sku30',
    SKU40: 'sku40',
    ENTERPRISE: 'enterprise'
};
export const externalToInternalTierNameMap = {
    basic: 'starter',
    growth: 'growth',
    starter: 'tier30',
    business: 'tier40',
    'Starter (Education Discount)': 'education',
    'Business (Education Discount)': 'education40',
    'Starter (Non-Profit Discount)': 'non_profit',
    'Business (Non-Profit Discount)': 'non_profit40',
    enterprise: 'enterprise'
};
export const CHECKOUT_CADENCES = {
    MONTHLY: 'monthly',
    ANNUAL: 'annual'
};
export const GOOGLE_ID_TOKEN = 'google_id_token';
export const STARTER_BUSINESS_MAXIMUM_USERS = 299;
export const MISSING_EMAIL = 'missing-email@example.com';
